import React from "react"
import { Link } from "gatsby"
import menu from "../images/menu.svg"
import tslogo from "../images/tslogo.svg"
import logo from "../images/logo.png"
import Overlay from "../components/overlay"
import appstoreLogo from "../images/appstore.svg"
import playLogo from "../images/playstore.svg"

const Page = class extends React.Component {
    toggleNavMenu = () => {
        var menu = document.querySelector(".submenu");
        if (menu.style.display === "") {
            menu.style.display = "none";
        } else {
            menu.style.display = "";
        }
    }

    render() {
        return(
            <div id="bannerAndHeader" >
              <Overlay showMenu={this.props.showMenu}/>
                <div class="banner">
                    <text>In partnership with</text>
                    <a href="https://www.mhranalytics.com/talksuite/" rel="noopener" target="_blank">
                        <img src={tslogo} alt="talksuite logo" height="18"/>
                    </a>
                </div>
                <div class="header">
                    <div class="show-mobile navbar">
                        <img src={menu} alt="menu" onClick={() => this.toggleNavMenu()}/>
                        <a class="headerLink" href="/"><img class="headerLogo" src={logo} alt="ChatForge logo" /></a>
                        <div class="emptyDivForFlexReasons"></div>
                    </div>
                    <div class="show-desktop navbar">
                        <a class="headerLink" href="/"><img class="headerLogo" src={logo} alt="ChatForge logo" /></a>
                        <div class="header-links-container">
                            <Link style={{"display": "none"}} class="header-links" to={`/`}>Our solutions</Link>
                            <Link class="header-links" to={`/conductor`}>Conductor</Link>
                            <Link class="header-links" to={`/sundial`}>Sundial</Link>
                            <Link class="header-links" to={`/blog`}>Blog</Link>
                            <a class="app-logo" href={`https://apps.apple.com/gb/app/apple-store/id1490107264`}><img src={appstoreLogo} alt="app store icon" /></a>
                            <a class="app-logo" href={`https://play.google.com/store/apps/details?id=com.chatforge.app&hl=en`}><img src={playLogo} alt="play store icon" /></a>
                        </div>
                    </div>
                </div>
                <div class="submenu" style={{display: 'none'}}>
                    <Link style={{"display": "none"}} className="submenu-item" to={`/`}>Our solutions</Link>
                    <Link className="submenu-item" to={`/conductor`}>Conductor</Link>
                    <Link className="submenu-item" to={`/sundial`}>Sundial</Link>
                    <Link className="submenu-item" to={`/blog`}>Blog</Link>
                    <div className="submenu-app-buttons">
                        <a class="app-logo app-logo-google" href={`https://apps.apple.com/gb/app/apple-store/id1490107264`}><img src={appstoreLogo} alt="app store icon" /></a>
                        <a class="app-logo" href={`https://play.google.com/store/apps/details?id=com.chatforge.app&hl=en`}><img src={playLogo} alt="play store icon" /></a>
                    </div>
                  </div>
            </div>
        )
    }
}

export default Page
