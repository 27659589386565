import React, { useState } from "react";
import Oval from '../images/oval.svg';
import "./chatbox.css";

const ChatState = {
    'peeking': 'peeking',
    'dismissed': 'dismissed',
    'loading': 'loading',
    'open': 'open',
    'closed': 'closed'
}

const ChatBox = () => {
    const [ chatState, setChatState ] = useState(ChatState.peeking);
    return <>
        <div className={chatState === ChatState.dismissed ? "ts-01-chat-closed" : "chatbox-container"}>
            <div id="ts-01-prefix-chat-placement" className={`peaking-container ${chatState}`}>
                <div id="ts-01-prefix-chat-placement-close" className="close" onClick={() => { setChatState(ChatState.dismissed) }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="24" viewBox="0 0 48 48">
                        <path fill="#fff" d="M38 12.83L35.17 10 24 21.17 12.83 10 10 12.83 21.17 24 10 35.17 12.83 38 24 26.83 35.17 38 38 35.17 26.83 24z"></path>
                    </svg>
                </div>
                <div className="card">
                    <p className="title">Talk to Chatforge!</p>
                    <p>Find out more about what Chatforge do, and how they could help your business.</p>
                    <button id="ts-01-prefix-chat-button" onClick={() => { if (ChatState.peeking) { setChatState(ChatState.loading) } }}>
                        {chatState === ChatState.loading ? <img alt="loader" height="22px" class="ts-01-prefix-hide" id="ts-01-prefix-chat-spinner" src={Oval} /> : <span>{chatState === ChatState.closed ? 'Resume chat' : 'Start chat'}</span>}
                    </button>
                </div>
            </div>
        </div>
        <div id="ts-01-prefix-main" className="clickable-bs" onClick={() => { setChatState(ChatState.closed) }}>
            <div id="ts-01-prefix-chatwrapper">
                <div id="ts-01-prefix-header" class="clickable-bs">
                    <h1 id="title">Talk to Chatforge! </h1>
                    <div className="close">
                        <svg className="clickable-bs" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 48 48">
                            <path className="clickable-bs" fill="rgba(0,0,0,0.4)" d="M38 12.83L35.17 10 24 21.17 12.83 10 10 12.83 21.17 24 10 35.17 12.83 38 24 26.83 35.17 38 38 35.17 26.83 24z"></path>
                        </svg>
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default ChatBox;