import React from "react"
import silogo from "../images/silogo.png"

const Page = class extends React.Component {
    render() {
        return (
            <div className={`${this.props.jarvisFooter? 'footer-new': 'footer'}`}>
                <a href="https://www.softwareimaging.com/" rel="noopener noreferrer" target="_blank"><img id="footerLogo" src={silogo} alt="Software Imaging logo" width="50px" /></a>
                <text>A proud member of the <b>Software Imaging</b> family</text>
            </div>
        )
    }
}

export default Page
