import React from "react";
import CookieConsent from "react-cookie-consent"
import ReactGA from 'react-ga';
import { Helmet } from "react-helmet"

import "./overlay.css";
import ChatBox from "./chatbox";

const Overlay = (props) => (
    <div className="overlay">
      <Helmet>
        <title>ChatForge | UK Chatbot Services</title>
        <meta name="description" content="We’re a group of people who not only have years of experience in designing and building chatbot solutions but we are deeply passionate about the power of using conversational interfaces in the right way."/>
      </Helmet>
      <CookieConsent
        containerClasses = "cookieBanner"
        buttonClasses = "cookieBannerAcceptButton"
        declineButtonClasses = "cookieBannerDeclineButton"
        contentClasses = "cookieBannerContent"
            disableStyles = {true}
            location="bottom"
            buttonText="Accept"
            enableDeclineButton = {true}
            flipButtons = {true}
            declineButtonText="Decline"
            cookieName = "gatsby-gdpr-google-analytics"
            cookieValue = {true}
            //Days before Cookie Expires
            expires = {1}
            onAccept = {() => {
                                window['ga-disable-UA-153687473-1'] = false;
                                ReactGA.initialize('UA-153687473-1')
                            }
                        }
            onDecline= {() => {
                                window['ga-disable-UA-153687473-1'] = true;
                            }
                        }
            
        > This website uses cookies to enhance the user experience. You can read our privacy policy <u><a class="linkOnDarkBackground" href="/privacy#cookies">here</a></u>.
      </CookieConsent>
      { props.showMenu ? <ChatBox></ChatBox> : <></> }
  </div>
)

export default Overlay;